/* 
:root {
    --clr : #fff;

}

.navigation{
    position: relative;
    width: 450px;
    height: 70px;
    background: #019988;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.navigation ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }

.navigation ul li{

    z-index: 1; 
    padding: 0;
    margin: 0;
    text-align: center;
    position: relative;
}
.navigation .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

.navigation ul li a .icon{
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.5em;
    text-align: center;
    transition: 0.5s;
    color: var(--clr);
}
.navigation ul li.active a .icon{
    transform: translateY(-32px);
}
.navigation ul li a .text{
    position: absolute;
    margin-top:30px;
    color: var(--clr);
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
}

.navigation ul li.active a .text{
    opacity: 1;
    transform: translateY(10px);
}   
.navigation .indicator{
    position: absolute;
    top: -20%;
    left: 0;
    width: 70px;
    height: 70px;
    background: #6CBAAF;
    border-radius: 50%;
        border: 8px solid #019988;
    transition: 0.5s;
    transform: translateX(calc((450px / 5) * var(--active-index) + ((450px / 5 - 70px) / 2)));

} */













:root {
    --indicator-width: 70px; 
    --indicator-position: 0px;
}

.navigation {
    width: 100%;
    height: 70px;
    background: #019988;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.navigation ul {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
}

.navigation ul li {
    z-index: 1;
    padding: 0;
    margin: 0;
    text-align: center;
    position: relative;
    flex: 1;
}

.navigation .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.navigation ul li a .icon {
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.5em;
    text-align: center;
    transition: 0.5s;
    color: white;
}

.navigation ul li.active a .icon {
    transform: translateY(-32px);
}

.navigation ul li a .text {
    position: absolute;
    margin-top: 30px;
    color: white;
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
}

.navigation ul li.active a .text {
    opacity: 1;
    transform: translateY(10px);
}

.navigation .indicator {
    position: absolute;
    top: -20%;
    left: 0;
    width: var(--indicator-width);
    max-width: 70px;
    /* height: var(--indicator-width); */
    /* max-height: 70px; */
    height: 70px;
    background: #6CBAAF;
    border-radius: 50%;
    border: 8px solid #019988;
    transition: 0.5s ease-out;
    /* transform: translateX(calc(var(--indicator-position) - 4%)); */
    transform: translateX(calc(var(--indicator-position) + (var(--indicator-width) / 2) - 50%));
}

